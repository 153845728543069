import { enums } from "@/constants/enums";
import { IReport } from "@/views/report/report";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: IReport) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData(params?: any) {
  const result = { permitId: params?.permitId ? parseInt(params.permitId) : null } as IReport;
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: IReport }) {
  //TODO:TS
  response.data.isRecurring = response.data.frequency !== "OneTime" && response.data.frequency !== "AsNeeded";
  addAuthRequestFields(response.data);
  return response.data;
}
function requestAdapter(data: any) {
  data.manualPeriodDates = enums.reportTypes.get(data.type)?.manualPeriodDates;
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit(data: any) {}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: any) {}
