import { RouterView } from "vue-router";

const names = {
  // analytics: "Analytics",
  disposedWasteReport: "DisposedWasteReport",
  divertedMaterialReport: "DivertedMaterialReport",
  generatedWasteReport: "GeneratedWasteReport",
  exportDisposalReportData: "ExportDisposalReportData",
  exportRecyclingReportData: "ExportRecyclingReportData",
  materialCategoryReport: "MaterialCategoryReport",
  organizationMaterialReport: "OrganizationMaterialReport",
  recycledMaterialReport: "RecycledMaterialReport",
  recyclingRateReport: "RecyclingRateReport",
  emailList: "EmailList",
  exportFacilityData: "ExportFacilityData",
};

export { names as analyticsRouteNames };

const analyticsRoutes = [
  {
    path: "/analytics",
    //    name: names.analytics,
    component: () => import("@/views/analytics/AnalyticsBase.vue"),
    redirect: { name: names.divertedMaterialReport },
    children: [
      // {
      //   path: "disposedWasteReport",
      //   name: names.disposedWasteReport,
      //   meta: { title: "Disposed Waste Report" },
      //   component: () => import("@/views/analytics/DisposedWasteReport.vue"),
      // },
      {
        path: "divertedMaterialReport",
        name: names.divertedMaterialReport,
        meta: { title: "Diverted Material Report" },
        component: () => import("@/views/analytics/DivertedMaterialReport.vue"),
      },
      {
        path: "generatedWasteReport",
        name: names.generatedWasteReport,
        meta: { title: "Generated Waste Report" },
        component: () => import("@/views/analytics/GeneratedWasteReport.vue"),
      },
      {
        path: "exportRecyclingReportData",
        name: names.exportRecyclingReportData,
        meta: { title: "Recycling Report Data Export", hideFilterChange: true },
        component: () => import("@/views/analytics/ExportRecyclingReportData.vue"),
      },
      {
        path: "exportDisposalReportData",
        name: names.exportDisposalReportData,
        meta: { title: "Disposal Report Data Export", hideFilterChange: true },
        component: () => import("@/views/analytics/ExportDisposalReportData.vue"),
      },
      {
        path: "materialCategoryReport",
        name: names.materialCategoryReport,
        meta: { title: "Material Category Report" },
        component: () => import("@/views/analytics/MaterialCategoryReport.vue"),
      },
      {
        path: "organizationMaterialReport",
        name: names.organizationMaterialReport,
        meta: { title: "Organization Material Report" },
        component: () => import("@/views/analytics/OrganizationMaterialReport.vue"),
      },

      {
        path: "recycledMaterialReport",
        name: names.recycledMaterialReport,
        meta: { title: "Recycled Material Report" },
        component: () => import("@/views/analytics/RecycledMaterialReport.vue"),
      },
      {
        path: "recyclingRateReport",
        name: names.recyclingRateReport,
        meta: { title: "Recycling Rate Report" },
        component: () => import("@/views/analytics/RecyclingRateReport.vue"),
      },
      {
        path: "emailList",
        name: names.emailList,
        meta: { title: "Email List Report" },
        component: () => import("@/views/analytics/EmailList.vue"),
      },
      {
        path: "exportFacilityData",
        name: names.exportFacilityData,
        meta: { title: "Facility Data Export", hideFilterChange: true },
        component: () => import("@/views/analytics/FacilityDataExport.vue"),
      },
    ],
  },
];
export default analyticsRoutes;
